<template>
  <page-template>
    <main-block>
      <block-head title="API Keys" :description="$t('pages.third_party_apis.page_desc')"></block-head>
      <block-content>
        <div class="d-flex py-5 justify-center bg-white" v-if="dataIsLoading">
          <ion-spinner></ion-spinner>
        </div>
        <card stretch v-else no-padding class="p-3">
          <template v-slot:grouped>
            <div class="card-inner" v-for="api in apiKeys" :key="'api'+api.id">
              <div class="between-center flex-wrap flex-md-nowrap g-3">
                <div class="nk-block-text">
                  <h6>{{ api.nice_name }}</h6>
                  <p>{{ api.description }}</p>
                  <template v-if="api.api_key.length || api.api_secret.length">
                    <div v-if="api.api_key.length" class="text-muted text-break">
                      <nio-icon icon="ni-lock-fill"></nio-icon>
                      <span class="nk-text-muted mr-2"> API {{$t('key')}}: </span> {{ api.api_key }}
                    </div>
                    <div v-if="api.api_secret.length" class="text-muted text-break">
                      <nio-icon icon="ni-lock-fill"></nio-icon>
                      <span class="nk-text-muted mr-2"> {{$t('pages.third_party_apis.api_secret')}}: </span> {{ api.api_secret }}
                    </div>
                  </template>
                  <div v-else class="text-muted">{{$t('pages.third_party_apis.no_credentials')}}.</div>
                </div>
                <div class="nk-block-actions">
                  <nk-button type="primary"
                             v-if="!api.api_key.length && !api.api_secret.length"
                             v-on:click="editAPI(api)">{{$t('Set')}} {{$t('credentials')}}</nk-button>
                  <nk-button type="dark" v-else v-on:click="editAPI(api)">{{ $t('Update')+' '+$t('credentials')}}</nk-button>
                </div>
              </div>
            </div>
          </template>
        </card>
      </block-content>
    </main-block>
    <ion-modal
        :is-open="isModalOpenRef"
        @didDismiss="setModalOpenStatus(false)"
        :css-class="isDesktop ? 'modal-web' : ''"
    >
      <i-modal :title="'API'+ $t('Credentials')" @modal-close="setModalOpenStatus(false)">
        <form-group>
          <label class="overline-title" for="api_name">API {{$t('Name')}}</label>
          <b-input id="api_name" v-model="editedAPI.nice_name"/>
        </form-group>
        <form-group>
          <label class="overline-title" for="api_key">API {{$t('Key')}}</label>
          <b-input id="api_key" v-model="editedAPI.api_key"/>
        </form-group>
        <form-group v-if="editedAPI.has_api_secret">
          <label class="overline-title" for="api_secret">API {{$t('Secret')}}</label>
          <b-input id="api_secret" v-model="editedAPI.api_secret"/>
        </form-group>
        <form-group>
          <label class="overline-title" for="api_description">{{$t('Description')}}</label>
          <textarea class="form-control" id="api_description" v-model="editedAPI.description"></textarea>
        </form-group>
        <template v-slot:footer class="p-5">
          <nk-button type="primary" class="mr-2" v-on:click="changeAPI">{{$t('Update')+' '+$t('Credentials')}}</nk-button>
          <a href="javascript:;" class="link link-light" v-on:click="setModalOpenStatus(false)">{{$t('Cancel')}}</a>
        </template>
      </i-modal>
    </ion-modal>
  </page-template>
</template>

<script>
import {loadingController, toastController, IonModal, IonSpinner, isPlatform} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import axios from "@/libs/axios"
import {Card} from "@core/components/cards";
import IModal from "@core/components/ion-modal/IModal";
import {useI18n} from "vue-i18n";

import "bootstrap"


import BlockHead from "@core/layouts/main-block/components/BlockHead";
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";
import FormGroup from "@core/layouts/form-group/FormGroup";
import BInput from "@core/components/bp-form/components/BInput";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    BInput,
    FormGroup,
    NkButton,
    NioIcon,
    BlockHead,
    Card,
    BlockContent,
    MainBlock,
    IonModal,
    IonSpinner,
    IModal,
  },
  setup() {

    const{t} = useI18n()
    const isDesktop   = isPlatform('desktop')
    let dataIsLoading = ref(false)
    let apiKeys   = ref([])
    let editedAPI = reactive({
      api_key: '',
      api_secret: '',
      description: '',
      has_api_secret: false,
      id: 0,
      nice_name: '',
    })
    let isModalOpenRef = ref(false)

    const getAPIKeys = async () => {
      dataIsLoading.value = true
      axios.post('/api/admin/settings/api_keys/list')
          .then( (resp) => {
            for(let x in resp.data.data){
              apiKeys.value.push( resp.data.data[x])
            }
          })
          .catch( err => {
            console.log('APIKeys.vue ajax error: ', err)
          })
          .then(()=> dataIsLoading.value = false)
    }

    onMounted(()=> getAPIKeys())

    const editAPI = ( api ) => {

      editedAPI.api_key = editedAPI.api_secret = t('unchanged')
      editedAPI.id             = api.id
      editedAPI.description    = api.description
      editedAPI.has_api_secret = api.has_api_secret
      editedAPI.nice_name      = api.nice_name
      setModalOpenStatus(true)
    }
    const setModalOpenStatus = (st) => isModalOpenRef.value = st
    const changeAPI = async () => {

      const loading = await loadingController.create({message: t('Processing...')})
      const toast   = await toastController.create({position: "top", duration: 3000})
      await loading.present()


      axios.post('/api/admin/settings/api_keys/update', editedAPI)
          .then( resp => {

            toast.message = resp.data.success ? t('Thank you! action completed') : resp.data.message
            toast.color   = resp.data.success ? "primary" : "danger"
            if( resp.data.success ){

              setModalOpenStatus(false)

              let data = resp.data.data[0]
              for( let x in apiKeys.value ){
                if( apiKeys.value[x].id === editedAPI.id ){
                  apiKeys.value[x].api_key     = data.api_key
                  apiKeys.value[x].api_secret  = data.api_secret
                  apiKeys.value[x].description = editedAPI.description
                  apiKeys.value[x].nice_name   = editedAPI.nice_name
                  break
                }
              }
            }
            toast.present()
          })
          .catch(error => {
            toast.message = t('Error')+': '+error.response.status+' '+error.response.statusText
            toast.color = "danger"
            toast.present()
          })
          .then( () => loading.dismiss())
    }

    return {
      apiKeys,
      changeAPI,
      dataIsLoading,
      editAPI,
      editedAPI,
      isDesktop,
      isModalOpenRef,
      setModalOpenStatus,
    }
  }
});

</script>
